import validate from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/pages/runtime/validate.js";
import feature_45flag_45global from "/home/runner/work/signalznow/signalznow/frontend/middleware/featureFlag.global.ts";
import manifest_45route_45rule from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  feature_45flag_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  manager: () => import("/home/runner/work/signalznow/signalznow/frontend/middleware/manager.ts"),
  "test-only": () => import("/home/runner/work/signalznow/signalznow/frontend/middleware/test-only.ts"),
  "sidebase-auth": () => import("/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.1_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_magicast@0.3_54922b49a076675f6d79e3d5b8655d08/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}