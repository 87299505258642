import { initializeSync } from "@effect-app/vue"
import * as Layer from "effect/Layer"
import * as Runtime from "effect/Runtime"
import { Effect, HttpClient, Option } from "@/utils/prelude"
import type {} from "#resources/lib"
import { SentrySdkLive, WebSdkLive } from "~/utils/observability"
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions"
import { ref } from "vue"
import { type HttpClientResponse, FetchHttpClient } from "@effect/platform"
import { useAuth } from "#imports"
import { Ref } from "effect-app"
import { ResponseError } from "@effect/platform/HttpClientError"
import type { useRuntimeConfig } from "#app"
import { ApiClientFactory } from "effect-app/client/apiClientFactory"

export const versionMatch = ref(true)

// TODO: make sure the runtime provides these
export type RT = ApiClientFactory

const hasAuthenticationError = (
  response: HttpClientResponse.HttpClientResponse,
) =>
  response.status === 401
    ? Effect.succeed(true)
    : response.status === 422
      ? Effect.map(
          response.json,
          json =>
            Array.isArray(json) &&
            json.some(
              _ =>
                typeof _ === "object" &&
                _._tag === "Exit" &&
                _.exit.failure?.error?._tag === "NotLoggedInError",
            ),
        )
      : Effect.succeed(false)

const openLoginPopup: Effect.Effect<void> = Effect.async<void>(resume => {
  // try popup, otherwise new tab
  const w =
    window.open("/refresh", undefined, "popup") || window.open("/refresh")
  if (w) {
    // I've tried onclose, addEventHandler("closing"), custom events, none seemed to work, except polling
    // TODO: time out polling?
    const iv = setInterval(() => {
      if (w.closed) {
        console.log("closed")
        resume(Effect.void)
        clearInterval(iv)
      }
    }, 100)
  } else {
    // otherwise ask the user if it's fine to reload his window
    if (confirm("refresh login?")) {
      window.location.href =
        "/refresh?callbackUrl=" + encodeURIComponent(window.location.href)
    }
    resume(Effect.die("no window"))
  }
})

const handleAuthenticationError = (client: HttpClient.HttpClient) =>
  Effect.gen(function* () {
    const refreshToken = Effect.promise(() => {
      // TODO: does this work/help?
      const { refresh } = useAuth()

      return refresh().then(x => {
        if (
          (x as { error: unknown }).error &&
          (x as { error: unknown }).error === "RefreshAccessTokenError"
        ) {
          return "RefreshAccessTokenError"
        }
        return null
      })
    }).pipe(Effect.andThen(x => (x ? openLoginPopup : Effect.void)))

    // create a latch. We only want API queries to proceed if the latch is open
    const latch = yield* Effect.makeLatch(true)
    // create a ref to check if the latch is open. We do so becase there is no way to check the status of the latch
    const isLatchOpen = yield* Ref.make(true)

    const latchedClient = client.pipe(HttpClient.tapRequest(() => latch.await))

    const lock = yield* Effect.makeSemaphore(1)
    const withLock = lock.withPermits(1)

    // now we tap the response to check if there is an authentication error

    const latchedClientWithAuthenticationErrorHandling = latchedClient.pipe(
      HttpClient.tap(response =>
        hasAuthenticationError(response).pipe(
          Effect.andThen(isAuthError =>
            Effect.gen(function* () {
              if (isAuthError) {
                const shouldRefresh = yield* withLock(
                  Effect.gen(function* () {
                    const canRefresh = Ref.get(isLatchOpen)
                    if (canRefresh) {
                      yield* latch.close
                      yield* Ref.set(isLatchOpen, false)
                    }
                    return canRefresh
                  }),
                )
                console.log("shouldRefresh", shouldRefresh)

                if (shouldRefresh) {
                  yield* refreshToken.pipe(
                    Effect.ensuring(
                      withLock(
                        Effect.all([latch.open, Ref.set(isLatchOpen, true)]),
                      ),
                    ),
                  )
                } // else { yield* latch.await } // not necessary, as on retry it will wait for the latch anyway

                return yield* Effect.fail(
                  new ResponseError({
                    reason: "StatusCode",
                    request: response.request,
                    response: { ...response, status: 401 },
                  }),
                )
              }
            }),
          ),
        ),
      ),
      HttpClient.retry({
        until: e => {
          const is401 = e._tag === "ResponseError" && e.response.status === 401
          return !is401
        },
        times: 3,
      }),
    )

    return latchedClientWithAuthenticationErrorHandling
  })

function makeRuntime(
  feVersion: string,
  env: string,
  isRemote: boolean,
  disableTracing: boolean,
) {
  const apiLayers = ApiClientFactory.layer({
    url: "/api/api",
    headers: Option.none(),
  }).pipe(
    Layer.provide(
      Layer.effect(
        HttpClient.HttpClient,
        HttpClient.HttpClient.pipe(
          Effect.andThen(handleAuthenticationError),
          Effect.map(
            HttpClient.tap(r =>
              Effect.sync(() => {
                const remoteFeVersion = r.headers["x-fe-version"]
                if (remoteFeVersion) {
                  versionMatch.value = feVersion === remoteFeVersion
                }
              }),
            ),
          ),
        ),
      ),
    ),
    Layer.provide(FetchHttpClient.layer),
  )

  const otelAttrs = {
    serviceName: "getsignalz-frontend",
    serviceVersion: feVersion,
    attributes: {
      [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: env,
      //["fe.device.id"]: deviceId,
    },
  }

  const rt: {
    runtime: Runtime.Runtime<RT>
    clean: () => void
  } = initializeSync(
    disableTracing
      ? apiLayers
      : apiLayers.pipe(
          Layer.merge(
            isRemote ? SentrySdkLive(otelAttrs, env) : WebSdkLive(otelAttrs),
          ),
        ),
  )
  const instance = {
    ...rt,
    runFork: Runtime.runFork(rt.runtime),
    runSync: Runtime.runSync(rt.runtime),
    runPromise: Runtime.runPromise(rt.runtime),
    runCallback: Runtime.runCallback(rt.runtime),
  }

  return instance
}

/*
  We read the configuration from the global var sent by server, embedded in the html document.
  The reason for this is, that we want to have the configuration available before the Nuxt app is initialized.
  Otherwise we can only initialize the runtime in nuxt plugin, script setup or middleware,
  which means we cannot do anything with the runtime in the root of modules, etc.

  Now we can use things like clientFor, which leverage the runtime, and export clients directly from modules.
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config = (globalThis as any).__NUXT__.config as ReturnType<
  typeof useRuntimeConfig
>
const isRemote = config.public.env !== "local-dev"
export const runtime = makeRuntime(
  config.public.feVersion,
  config.public.env,
  isRemote,
  !isRemote && !config.public.telemetry,
)
