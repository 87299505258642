import { S, type Types } from "effect-app"
import { NonEmptyString255, optional, prefixedStringId, type StringIdBrand } from "effect-app/Schema"
import type { Brand } from "effect/Brand"
import { AlgorithmIntervalSettings, AlgorithmIntervalSettingsRefined, ATRAlgorithmIntervalSettings, ATRAlgorithmIntervalSettingsRefined, refineIntervalSettings, RollingAlgorithmIntervalSettings, RollingAlgorithmIntervalSettingsRefined, unrefineIntervalSettings } from "./AlgorithmSettings.js"
import { TickerSymbol } from "./Security.js"

export interface OptimizationBrand extends Types.Simplify<Brand<"Optimization"> & StringIdBrand> {}
export type OptimizationId = string & OptimizationBrand
export const OptimizationId = prefixedStringId<OptimizationId>()("optimization", "Optimization")

export class SingleOptimization extends S.ExtendedTaggedClass<SingleOptimization, SingleOptimization.Encoded>()(
  "SingleOptimization",
  {
    // cannot use WMA/EMA because it doesn't produce sell signals
    single: S.Union(ATRAlgorithmIntervalSettings, RollingAlgorithmIntervalSettings)
  }
) {}

export class EntryExitOptimization
  extends S.ExtendedTaggedClass<EntryExitOptimization, EntryExitOptimization.Encoded>()(
    "EntryExitOptimization",
    {
      entry: AlgorithmIntervalSettings,
      // cannot use WMA/EMA because it doesn't produce sell signals
      exit: S.Union(ATRAlgorithmIntervalSettings, RollingAlgorithmIntervalSettings),
      eagerBuy: S.Boolean
    }
  )
{}

// the following are the input for workers, please keep in sync with SingleOptimization and EntryExitOptimization
export class SingleOptimizationRefined
  extends S.ExtendedTaggedClass<SingleOptimizationRefined, SingleOptimizationRefined.Encoded>()(
    "SingleOptimizationRefined",
    {
      // cannot use WMA/EMA because it doesn't produce sell signals
      single: S.Union(ATRAlgorithmIntervalSettingsRefined, RollingAlgorithmIntervalSettingsRefined)
    }
  )
{}

export class EntryExitOptimizationRefined
  extends S.ExtendedTaggedClass<EntryExitOptimizationRefined, EntryExitOptimizationRefined.Encoded>()(
    "EntryExitOptimizationRefined",
    {
      entry: AlgorithmIntervalSettingsRefined,
      // cannot use WMA/EMA because it doesn't produce sell signals
      exit: S.Union(ATRAlgorithmIntervalSettingsRefined, RollingAlgorithmIntervalSettingsRefined),
      eagerBuy: S.Boolean
    }
  )
{}

/**
 * transform intervals and step values into arrays of values to be used in the optimization
 */
export function refineOptimization<
  K extends Optimization["_tag"]
>(opt: Optimization & { _tag: K }) {
  return {
    get SingleOptimization() {
      return new SingleOptimizationRefined({
        single: refineIntervalSettings((opt as SingleOptimization).single)
      })
    },
    get EntryExitOptimization() {
      return new EntryExitOptimizationRefined({
        entry: refineIntervalSettings((opt as EntryExitOptimization).entry),
        exit: refineIntervalSettings((opt as EntryExitOptimization).exit),
        eagerBuy: (opt as EntryExitOptimization).eagerBuy
      })
    }
  }[opt._tag]
}

export function unrefineOptimization<
  K extends OptimizationRefined["_tag"]
>(
  refinedOpd: OptimizationRefined & { _tag: K }
) {
  return {
    get SingleOptimizationRefined() {
      const _refinedOpd = refinedOpd as SingleOptimizationRefined
      return new SingleOptimization({
        single: unrefineIntervalSettings(_refinedOpd.single)
      })
    },
    get EntryExitOptimizationRefined() {
      const _refinedOpd = refinedOpd as EntryExitOptimizationRefined
      return new EntryExitOptimization({
        entry: unrefineIntervalSettings(_refinedOpd.entry),
        exit: unrefineIntervalSettings(_refinedOpd.exit),
        eagerBuy: _refinedOpd.eagerBuy
      })
    }
  }[refinedOpd._tag]
}

// this ADT is the bare minimum to represent the result of the optimization
export class BestATRAlgorithmSettings
  extends S.ExtendedTaggedClass<BestATRAlgorithmSettings, BestATRAlgorithmSettings.Encoded>()(
    "BestATRAlgorithmSettings",
    {
      period: S.PositiveInt,
      multiplier: S.PositiveNumber
    }
  )
{}

export class BestEMAWMAAlgorithmSettings
  extends S.ExtendedTaggedClass<BestEMAWMAAlgorithmSettings, BestATRAlgorithmSettings.Encoded>()(
    "BestEMAWMAAlgorithmSettings",
    {
      wma: S.PositiveInt,
      ema: S.PositiveInt
    }
  )
{}

export class BestRollingAlgorithmSettings
  extends S.ExtendedTaggedClass<BestRollingAlgorithmSettings, BestATRAlgorithmSettings.Encoded>()(
    "BestRollingAlgorithmSettings",
    {
      period: S.PositiveInt,
      multiplier: S.PositiveNumber,
      unit: S.PositiveInt
    }
  )
{}

export class BestSingleAlgorithmSettings
  extends S.ExtendedTaggedClass<BestSingleAlgorithmSettings, BestATRAlgorithmSettings.Encoded>()(
    "BestSingleAlgorithmSettings",
    {
      single: S.Union(BestATRAlgorithmSettings, BestRollingAlgorithmSettings)
    }
  )
{}

export class BestEntryExitAlgorithmSettings
  extends S.ExtendedTaggedClass<BestEntryExitAlgorithmSettings, BestEntryExitAlgorithmSettings.Encoded>()(
    "BestEntryExitAlgorithmSettings",
    {
      entry: S.Union(BestATRAlgorithmSettings, BestEMAWMAAlgorithmSettings, BestRollingAlgorithmSettings),
      exit: S.Union(BestATRAlgorithmSettings, BestRollingAlgorithmSettings)
    }
  )
{}

export const BestAlgorithmSettings = S.Union(BestSingleAlgorithmSettings, BestEntryExitAlgorithmSettings)
export type BestAlgorithmSettings = S.Schema.Type<typeof BestAlgorithmSettings>
// end of adt

export class SymbolPerformanceV0
  extends S.ExtendedTaggedClass<SymbolPerformanceV0, SymbolPerformanceV0.Encoded>()("SymbolPerformanceV0", {
    overallPerformance: S.Number,
    oldestClose: S.Number,
    buyAndHold: S.Number,
    lastInvestmentValue: S.Number,
    symbol: NonEmptyString255
  })
{}

export class SymbolPerformanceV1
  extends S.ExtendedTaggedClass<SymbolPerformanceV1, SymbolPerformanceV1.Encoded>()("SymbolPerformanceV1", {
    buyPrice: S.Number,
    latestClosePrice: S.Number,
    algorithmFinalValue: S.Number,
    symbol: NonEmptyString255
  })
{}

// fill with stuff like an error message, a result, temporary info
export class RunningOptimization
  extends S.ExtendedTaggedClass<RunningOptimization, RunningOptimization.Encoded>()("RunningOptimization", {
    startedAt: S.Date.withDefault,
    progress: S.Number, // [0, 100],
    doneCombinations: S.Number,
    totalCombinations: S.Number
  })
{}
export class CompletedOptimization
  extends S.ExtendedTaggedClass<CompletedOptimization, CompletedOptimization.Encoded>()("CompletedOptimization", {
    result: BestAlgorithmSettings,
    startedAt: S.Date,
    endedAt: S.Date.withDefault,
    symbolsPerformances: S.Union(
      S.Record({ key: NonEmptyString255, value: SymbolPerformanceV0 }),
      S.Record({ key: NonEmptyString255, value: SymbolPerformanceV1 })
    ),
    totalCombinations: S.Number
  })
{}
export class FailedOptimization
  extends S.ExtendedTaggedClass<FailedOptimization, FailedOptimization.Encoded>()("FailedOptimization", {
    reason: S.String,
    startedAt: S.Date,
    endedAt: S.Date.withDefault
  })
{}

export const OptimizationStatus = S.Union(RunningOptimization, CompletedOptimization, FailedOptimization)
export type OptimizationStatus = S.Schema.Type<typeof OptimizationStatus>

export const Optimization = S.Union(SingleOptimization, EntryExitOptimization)
export type Optimization = S.Schema.Type<typeof Optimization>

export const OptimizationRefined = S.Union(SingleOptimizationRefined, EntryExitOptimizationRefined)
export type OptimizationRefined = S.Schema.Type<typeof OptimizationRefined>

export class OptimizationWithStatusMono
  extends S.ExtendedTaggedClass<OptimizationWithStatusMono, OptimizationWithStatusMono.Encoded>()(
    // it's not OptimizationWithStatusMono because of DB retro-compatibility
    "OptimizationWithStatus",
    {
      id: OptimizationId.withDefault,
      name: optional(NonEmptyString255),
      status: OptimizationStatus,
      optimization: Optimization,
      symbols: S.NonEmptyArray(TickerSymbol),
      dateFrom: S.Date
    }
  )
{}

export class OptimizationWithStatusBatched
  extends S.ExtendedTaggedClass<OptimizationWithStatusBatched, OptimizationWithStatusBatched.Encoded>()(
    "OptimizationWithStatusBatched",
    {
      id: OptimizationId.withDefault,
      name: optional(NonEmptyString255),
      batches: S.NonEmptyArray(S.Struct({
        optimization: Optimization,
        status: OptimizationStatus
      })),
      symbols: S.NonEmptyArray(TickerSymbol),
      optimization: Optimization,
      status: OptimizationStatus,
      dateFrom: S.Date
    }
  )
{}

export class IterativeOptimization
  extends S.ExtendedTaggedClass<IterativeOptimization, IterativeOptimization.Encoded>()(
    "IterativeOptimization",
    {
      id: OptimizationId.withDefault,
      name: optional(NonEmptyString255),
      optimization: Optimization,
      symbols: S.NonEmptyArray(TickerSymbol),
      dateFrom: S.Date,
      minPerformance: S.PositiveNumber, // % above the one of buy & hold
      iterations: S.Array(OptimizationId), // ids of batched optimizations
      // simple, just to know if there are other iterations to run
      status: S.Union(
        S.Struct({
          _tag: S.Literal("RunningIterativeOptimization"),
          startedAt: S.Date.withDefault
        }),
        S.Struct({
          _tag: S.Literal("TerminatedIterativeOptimization"),
          startedAt: S.Date,
          endedAt: S.Date.withDefault
        })
      )
    }
  )
{}

export class IterativeOptimizationReified
  extends S.ExtendedTaggedClass<IterativeOptimizationReified, IterativeOptimizationReified.Encoded>()(
    "IterativeOptimizationReified",
    {
      ...IterativeOptimization.omit("_tag"),
      iterations: S.Array(OptimizationWithStatusMono)
    }
  )
{}

export const OptimizationContainer = S.Union(
  OptimizationWithStatusMono,
  OptimizationWithStatusBatched,
  IterativeOptimization
)
export type OptimizationContainer = typeof OptimizationContainer.Type
export namespace OptimizationContainer {
  export type From = typeof OptimizationContainer.Encoded
}

/**
 * Compute the number of total combinations for a given optimization
 */
export function totalCombinationsCalc(o: OptimizationRefined, symbolCount: number): number {
  let toRet = symbolCount
  switch (o._tag) {
    case "SingleOptimizationRefined": {
      const { single } = o

      switch (single._tag) {
        case "ATRAlgorithmIntervalSettingsRefined": {
          toRet *= single.multipliers.length * single.periods.length
          break
        }
        case "RollingAlgorithmIntervalSettingsRefined": {
          toRet *= single.multipliers.length * single.periods.length * single.unitSizes.length
          break
        }
      }
      break
    }
    case "EntryExitOptimizationRefined": {
      const { entry, exit } = o

      switch (entry._tag) {
        case "ATRAlgorithmIntervalSettingsRefined": {
          toRet *= entry.multipliers.length * entry.periods.length
          break
        }
        case "RollingAlgorithmIntervalSettingsRefined": {
          toRet *= entry.multipliers.length * entry.periods.length * entry.unitSizes.length
          break
        }
        case "WMAEMAAlgorithmIntervalSettingsRefined": {
          toRet *= entry.EMATimeIntervals.length * entry.WMATimeIntervals.length
          break
        }
      }

      switch (exit._tag) {
        case "ATRAlgorithmIntervalSettingsRefined": {
          toRet *= exit.multipliers.length * exit.periods.length
          break
        }
        case "RollingAlgorithmIntervalSettingsRefined": {
          toRet *= exit.multipliers.length * exit.periods.length * exit.unitSizes.length
          break
        }
      }
      break
    }
  }

  return toRet
}

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace SingleOptimization {
  export interface Encoded extends S.Struct.Encoded<typeof SingleOptimization["fields"]> {}
}
export namespace EntryExitOptimization {
  export interface Encoded extends S.Struct.Encoded<typeof EntryExitOptimization["fields"]> {}
}
export namespace SingleOptimizationRefined {
  export interface Encoded extends S.Struct.Encoded<typeof SingleOptimizationRefined["fields"]> {}
}
export namespace EntryExitOptimizationRefined {
  export interface Encoded extends S.Struct.Encoded<typeof EntryExitOptimizationRefined["fields"]> {}
}
export namespace BestATRAlgorithmSettings {
  export interface Encoded extends S.Struct.Encoded<typeof BestATRAlgorithmSettings["fields"]> {}
}
export namespace BestEMAWMAAlgorithmSettings {
  export interface Encoded extends S.Struct.Encoded<typeof BestEMAWMAAlgorithmSettings["fields"]> {}
}
export namespace BestRollingAlgorithmSettings {
  export interface Encoded extends S.Struct.Encoded<typeof BestRollingAlgorithmSettings["fields"]> {}
}
export namespace BestSingleAlgorithmSettings {
  export interface Encoded extends S.Struct.Encoded<typeof BestSingleAlgorithmSettings["fields"]> {}
}
export namespace BestEntryExitAlgorithmSettings {
  export interface Encoded extends S.Struct.Encoded<typeof BestEntryExitAlgorithmSettings["fields"]> {}
}
export namespace SymbolPerformanceV0 {
  export interface Encoded extends S.Struct.Encoded<typeof SymbolPerformanceV0["fields"]> {}
}
export namespace SymbolPerformanceV1 {
  export interface Encoded extends S.Struct.Encoded<typeof SymbolPerformanceV1["fields"]> {}
}
export namespace RunningOptimization {
  export interface Encoded extends S.Struct.Encoded<typeof RunningOptimization["fields"]> {}
}
export namespace CompletedOptimization {
  export interface Encoded extends S.Struct.Encoded<typeof CompletedOptimization["fields"]> {}
}
export namespace FailedOptimization {
  export interface Encoded extends S.Struct.Encoded<typeof FailedOptimization["fields"]> {}
}
export namespace OptimizationWithStatusMono {
  export interface Encoded extends S.Struct.Encoded<typeof OptimizationWithStatusMono["fields"]> {}
}
export namespace OptimizationWithStatusBatched {
  export interface Encoded extends S.Struct.Encoded<typeof OptimizationWithStatusBatched["fields"]> {}
}
export namespace IterativeOptimization {
  export interface Encoded extends S.Struct.Encoded<typeof IterativeOptimization["fields"]> {}
}
export namespace IterativeOptimizationReified {
  export interface Encoded extends S.Struct.Encoded<typeof IterativeOptimizationReified["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
