import type { Locale } from "#models/User"
import { ref, computed } from "vue"
import { useAuth } from "#imports"
import type { Session } from "next-auth"

/**
 * We would like the locale to be part of the user session,
 * but we want to delay implementing calling auth0 management api
 * and adding locale to JWT.
 */

export const currentLocale = ref(getLocale())

function getLocale() {
  return (window.localStorage.getItem("locale") as Locale | null) || "de"
}

window.addEventListener("storage", () => {
  refreshLocaleIfNeeded(getLocale())
})

function refreshLocaleIfNeeded(locale: Locale) {
  if (currentLocale.value !== locale) {
    window.location.reload()
    return true
  }
  return false
}

export function setLocale(locale: Locale) {
  localStorage.setItem("locale", locale)
  return refreshLocaleIfNeeded(locale)
}

export function useAuthUser(): UserSession {
  const user = useOptionalSessionUser()
  if (!user.value) {
    throw new Error("Accessed user while not authenticated")
  }
  return user.value
}

type Base = Session["user"]
export interface UserSession extends Base {
  locale: Locale
}

export function useOptionalSessionUser() {
  const s = useAuth()

  return computed<UserSession | null>(() => {
    if (!s.data.value) {
      return null
    }

    return {
      ...s.data.value.user,
      locale: currentLocale.value,
    }
  })
}
