import { S, type Types } from "effect-app"
import { PositiveInt, PositiveNumber } from "effect-app/Schema"

export class ATRAlgorithmSettings extends S.TaggedClass<ATRAlgorithmSettings>()("ATRAlgorithmSettings", {
  period: PositiveInt,
  multiplier: PositiveNumber,
  algorithm: S.Literal("ATR_LONG", "ATR_SHORT")
}) {}

export class WMAEMAAlgorithmSettings extends S.TaggedClass<WMAEMAAlgorithmSettings>()("WMAEMAAlgorithmSettings", {
  batchByWeek: S.Boolean.pipe(S.withDefaultConstructor(() => false)),
  WMATimeInterval: PositiveInt,
  EMATimeInterval: PositiveInt
}) {}

export class RollingAlgorithmSettings extends S.TaggedClass<RollingAlgorithmSettings>()("RollingAlgorithmSettings", {
  period: PositiveInt,
  multiplier: PositiveNumber,
  unitSize: PositiveInt
}) {}

export const AlgorithmSettings = S.Union(ATRAlgorithmSettings, WMAEMAAlgorithmSettings, RollingAlgorithmSettings)
export type AlgorithmSettings = S.Schema.Type<typeof AlgorithmSettings>

function convertAlgorithmToIntervalSchemaFields<
  A,
  I,
  R,
  SF extends S.Struct.Fields,
  PTT extends (keyof SF) & string,
  PTD extends (keyof SF) & string
>(
  schema: S.Schema<A, I, R> & { fields: SF },
  { propsToDelete, propsToTransform }: {
    // other props will be copied as they are
    propsToTransform: PTT[]
    propsToDelete: PTD[]
  }
) {
  const res: Record<string, unknown> = {}

  for (const key in schema.fields) {
    if ((propsToDelete as (keyof SF)[]).includes(key)) continue

    if ((propsToTransform as (keyof SF)[]).includes(key)) {
      res[`${key}StartingValue`] = schema.fields[key]
      res[`${key}EndingValue`] = schema.fields[key]
      res[`${key}StepValue`] = schema.fields[key]
    } else {
      res[key] = schema.fields[key]
    }
  }

  return res as Types.Simplify<
    & { [K in Exclude<keyof SF, PTT | PTD>]: SF[K] }
    & { [K in Exclude<PTT, PTD> as `${K}StartingValue` | `${K}EndingValue` | `${K}StepValue`]: SF[K] }
  >
}

function convertAlgorithmToIntervalRefinedSchemaFields<
  A,
  I,
  R,
  SF extends S.Struct.Fields,
  PTT extends (keyof SF) & string,
  PTD extends (keyof SF) & string
>(
  schema: S.Schema<A, I, R> & { fields: SF },
  { propsToDelete, propsToTransform }: {
    // other props will be copied as they are
    propsToTransform: PTT[]
    propsToDelete: PTD[]
  }
) {
  const res: Record<string, unknown> = {}

  for (const key in schema.fields) {
    const field = schema.fields[key]

    if ((propsToDelete as (keyof SF)[]).includes(key)) continue

    if ((propsToTransform as (keyof SF)[]).includes(key)) {
      // ACTHUNG: THIS DOES NOT SUPPORT PROPERTY SIGNATURES (so cannot use default values)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      res[`${key}s`] = S.Array(field as any)
      // keep to reverse the process
      res[`${key}StepValue`] = field
    } else {
      res[key] = field
    }
  }

  return res as Types.Simplify<
    & { [K in Exclude<keyof SF, PTT | PTD>]: SF[K] }
    // @ts-expect-error SF[K] is a struct field
    & { [K in Exclude<PTT, PTD> as `${K}s`]: S.Array$<SF[K]> }
    & { [K in Exclude<PTT, PTD> as `${K}StepValue`]: SF[K] }
  >
}

export class ATRAlgorithmIntervalSettings extends S.TaggedClass<ATRAlgorithmIntervalSettings>()(
  "ATRAlgorithmIntervalSettings",
  convertAlgorithmToIntervalSchemaFields(
    ATRAlgorithmSettings,
    {
      propsToTransform: ["period", "multiplier"],
      propsToDelete: ["_tag"]
    }
  )
) {}

export class WMAEMAAlgorithmIntervalSettings extends S.TaggedClass<WMAEMAAlgorithmIntervalSettings>()(
  "WMAEMAAlgorithmIntervalSettings",
  convertAlgorithmToIntervalSchemaFields(
    WMAEMAAlgorithmSettings,
    {
      propsToTransform: ["WMATimeInterval", "EMATimeInterval"],
      propsToDelete: ["_tag"]
    }
  )
) {}

export class RollingAlgorithmIntervalSettings extends S.TaggedClass<RollingAlgorithmIntervalSettings>()(
  "RollingAlgorithmIntervalSettings",
  convertAlgorithmToIntervalSchemaFields(
    RollingAlgorithmSettings,
    {
      propsToTransform: ["period", "multiplier", "unitSize"],
      propsToDelete: ["_tag"]
    }
  )
) {}

export const AlgorithmIntervalSettings = S.Union(
  ATRAlgorithmIntervalSettings,
  WMAEMAAlgorithmIntervalSettings,
  RollingAlgorithmIntervalSettings
)
export type AlgorithmIntervalSettings = S.Schema.Type<typeof AlgorithmIntervalSettings>

export class ATRAlgorithmIntervalSettingsRefined extends S.TaggedClass<ATRAlgorithmIntervalSettingsRefined>()(
  "ATRAlgorithmIntervalSettingsRefined",
  convertAlgorithmToIntervalRefinedSchemaFields(
    ATRAlgorithmSettings,
    {
      propsToTransform: ["period", "multiplier"],
      propsToDelete: ["_tag"]
    }
  )
) {}

export class WMAEMAAlgorithmIntervalSettingsRefined extends S.TaggedClass<WMAEMAAlgorithmIntervalSettingsRefined>()(
  "WMAEMAAlgorithmIntervalSettingsRefined",
  convertAlgorithmToIntervalRefinedSchemaFields(
    WMAEMAAlgorithmSettings,
    {
      propsToTransform: ["WMATimeInterval", "EMATimeInterval"],
      propsToDelete: ["_tag"]
    }
  )
) {}

export class RollingAlgorithmIntervalSettingsRefined extends S.TaggedClass<RollingAlgorithmIntervalSettingsRefined>()(
  "RollingAlgorithmIntervalSettingsRefined",
  convertAlgorithmToIntervalRefinedSchemaFields(
    RollingAlgorithmSettings,
    {
      propsToTransform: ["period", "multiplier", "unitSize"],
      propsToDelete: ["_tag"]
    }
  )
) {}

export const AlgorithmIntervalSettingsRefined = S.Union(
  ATRAlgorithmIntervalSettingsRefined,
  WMAEMAAlgorithmIntervalSettingsRefined,
  RollingAlgorithmIntervalSettingsRefined
)
export type AlgorithmIntervalSettingsRefined = S.Schema.Type<typeof AlgorithmIntervalSettingsRefined>

/**
 * transform intervals and step values into arrays of values to be used in the optimization
 */
export function refineIntervalSettings<
  K extends AlgorithmIntervalSettings["_tag"]
>(is: AlgorithmIntervalSettings & { _tag: K }) {
  return {
    get ATRAlgorithmIntervalSettings() {
      const atr_is = is as ATRAlgorithmIntervalSettings
      const periods: PositiveInt[] = []
      const multipliers: PositiveNumber[] = []

      // todo: use an effect?
      if (
        atr_is.periodStartingValue > atr_is.periodEndingValue
        || atr_is.multiplierStartingValue > atr_is.multiplierEndingValue
      ) {
        throw new Error("Starting value must be less than or equal to ending value")
      }

      for (
        let n = +atr_is.periodStartingValue;
        n <= atr_is.periodEndingValue;
        n += atr_is.periodStepValue
      ) {
        periods.push(PositiveInt(n))
      }
      for (
        let n = +atr_is.multiplierStartingValue;
        n <= atr_is.multiplierEndingValue;
        n += atr_is.multiplierStepValue
      ) {
        multipliers.push(PositiveNumber(n))
      }

      return new ATRAlgorithmIntervalSettingsRefined({
        periods,
        multipliers,
        algorithm: atr_is.algorithm,
        periodStepValue: atr_is.periodStepValue,
        multiplierStepValue: atr_is.multiplierStepValue
      })
    },
    get WMAEMAAlgorithmIntervalSettings() {
      const wmaema_is = is as WMAEMAAlgorithmIntervalSettings
      const wmas: PositiveInt[] = []
      const emas: PositiveInt[] = []

      // todo: use an effect?
      if (
        wmaema_is.WMATimeIntervalStartingValue > wmaema_is.WMATimeIntervalEndingValue
        || wmaema_is.EMATimeIntervalStartingValue > wmaema_is.EMATimeIntervalEndingValue
      ) {
        throw new Error("Starting value must be less than or equal to ending value")
      }

      for (
        let n = +wmaema_is.WMATimeIntervalStartingValue;
        n <= wmaema_is.WMATimeIntervalEndingValue;
        n += wmaema_is.WMATimeIntervalStepValue
      ) {
        wmas.push(PositiveInt(n))
      }
      for (
        let n = +wmaema_is.EMATimeIntervalStartingValue;
        n <= wmaema_is.EMATimeIntervalEndingValue;
        n += wmaema_is.EMATimeIntervalStepValue
      ) {
        emas.push(PositiveInt(n))
      }

      return new WMAEMAAlgorithmIntervalSettingsRefined({
        WMATimeIntervals: wmas,
        EMATimeIntervals: emas,
        batchByWeek: wmaema_is.batchByWeek,
        EMATimeIntervalStepValue: wmaema_is.EMATimeIntervalStepValue,
        WMATimeIntervalStepValue: wmaema_is.WMATimeIntervalStepValue
      })
    },
    get RollingAlgorithmIntervalSettings() {
      const rolling_is = is as RollingAlgorithmIntervalSettings
      const periods: PositiveInt[] = []
      const multipliers: PositiveNumber[] = []
      const unitSizes: PositiveInt[] = []

      // todo: use an effect?
      if (
        rolling_is.periodStartingValue > rolling_is.periodEndingValue
        || rolling_is.multiplierStartingValue > rolling_is.multiplierEndingValue
        || rolling_is.unitSizeStartingValue > rolling_is.unitSizeEndingValue
      ) {
        throw new Error("Starting value must be less than or equal to ending value")
      }

      for (
        let n = +rolling_is.periodStartingValue;
        n <= rolling_is.periodEndingValue;
        n += rolling_is.periodStepValue
      ) {
        periods.push(PositiveInt(n))
      }
      for (
        let n = +rolling_is.multiplierStartingValue;
        n <= rolling_is.multiplierEndingValue;
        n += rolling_is.multiplierStepValue
      ) {
        multipliers.push(PositiveNumber(n))
      }
      for (
        let n = +rolling_is.unitSizeStartingValue;
        n <= rolling_is.unitSizeEndingValue;
        n += rolling_is.unitSizeStepValue
      ) {
        unitSizes.push(PositiveInt(n))
      }

      return new RollingAlgorithmIntervalSettingsRefined({
        periods,
        multipliers,
        unitSizes,
        periodStepValue: rolling_is.periodStepValue,
        multiplierStepValue: rolling_is.multiplierStepValue,
        unitSizeStepValue: rolling_is.unitSizeStepValue
      })
    }
  }[is._tag]
}

export function unrefineIntervalSettings<
  K extends AlgorithmIntervalSettingsRefined["_tag"]
>(isr: AlgorithmIntervalSettingsRefined & { _tag: K }) {
  return {
    get ATRAlgorithmIntervalSettingsRefined() {
      const atr_isr = isr as ATRAlgorithmIntervalSettingsRefined
      return new ATRAlgorithmIntervalSettings({
        periodStartingValue: atr_isr.periods[0]!,
        periodEndingValue: atr_isr.periods[atr_isr.periods.length - 1]!,
        periodStepValue: atr_isr.periodStepValue,
        multiplierStartingValue: atr_isr.multipliers[0]!,
        multiplierEndingValue: atr_isr.multipliers[atr_isr.multipliers.length - 1]!,
        multiplierStepValue: atr_isr.multiplierStepValue,
        algorithm: atr_isr.algorithm
      })
    },
    get WMAEMAAlgorithmIntervalSettingsRefined() {
      const wmaema_isr = isr as WMAEMAAlgorithmIntervalSettingsRefined
      return new WMAEMAAlgorithmIntervalSettings({
        WMATimeIntervalStartingValue: wmaema_isr.WMATimeIntervals[0]!,
        WMATimeIntervalEndingValue: wmaema_isr.WMATimeIntervals[wmaema_isr.WMATimeIntervals.length - 1]!,
        WMATimeIntervalStepValue: wmaema_isr.WMATimeIntervalStepValue,
        EMATimeIntervalStartingValue: wmaema_isr.EMATimeIntervals[0]!,
        EMATimeIntervalEndingValue: wmaema_isr.EMATimeIntervals[wmaema_isr.EMATimeIntervals.length - 1]!,
        EMATimeIntervalStepValue: wmaema_isr.EMATimeIntervalStepValue,
        batchByWeek: wmaema_isr.batchByWeek
      })
    },
    get RollingAlgorithmIntervalSettingsRefined() {
      const rolling_isr = isr as RollingAlgorithmIntervalSettingsRefined
      return new RollingAlgorithmIntervalSettings({
        periodStartingValue: rolling_isr.periods[0]!,
        periodEndingValue: rolling_isr.periods[rolling_isr.periods.length - 1]!,
        periodStepValue: rolling_isr.periodStepValue,
        multiplierStartingValue: rolling_isr.multipliers[0]!,
        multiplierEndingValue: rolling_isr.multipliers[rolling_isr.multipliers.length - 1]!,
        multiplierStepValue: rolling_isr.multiplierStepValue,
        unitSizeStartingValue: rolling_isr.unitSizes[0]!,
        unitSizeEndingValue: rolling_isr.unitSizes[rolling_isr.unitSizes.length - 1]!,
        unitSizeStepValue: rolling_isr.unitSizeStepValue
      })
    }
  }[isr._tag]
}
