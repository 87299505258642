import { S } from "effect-app"
import { Email, NonEmptyString, NonEmptyString2k, optional, type Schema } from "effect-app/Schema"

export const MailContent = S.Union(
  S.Struct({ text: NonEmptyString }),
  S.Struct({ html: NonEmptyString }),
  S.Struct({ templateId: NonEmptyString, dynamicTemplateData: optional(S.Record({ key: S.String, value: S.Unknown })) })
)

const Single = S.Union(Email, S.Struct({ name: S.String, email: Email }))
type Single = S.Schema.Type<typeof Single>

const Multiple = S.NonEmptyArray(Single)
type Multiple = S.Schema.Type<typeof Multiple>

export const Recipient = S.Union(Single, Multiple)
export type Recipient = S.Schema.Type<typeof Recipient>

export type MailContent = Schema.Type<typeof MailContent>

export class MailSubjectContent extends S.ExtendedClass<MailSubjectContent, MailSubjectContent.Encoded>()({
  subject: NonEmptyString2k,
  content: MailContent
}) {}

export class MailMessage extends S.ExtendedClass<MailMessage, MailMessage.Encoded>()({
  ...MailSubjectContent.fields,
  to: Recipient
}) {}

// codegen:start {preset: model}
//
/* eslint-disable */
export namespace MailSubjectContent {
  export interface Encoded extends S.Struct.Encoded<typeof MailSubjectContent["fields"]> {}
}
export namespace MailMessage {
  export interface Encoded extends S.Struct.Encoded<typeof MailMessage["fields"]> {}
}
/* eslint-enable */
//
// codegen:end
//
