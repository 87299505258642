<template>
  <div>
    <FixedNuxtErrorBoundary @error="handleError">
      <template #error="{ error }">
        <slot name="error" :error="error as E">
          <p>
            An unexpected error occurred
            <span v-if="config.public.env !== 'prod'">{{ error }}</span>
          </p>
        </slot>
      </template>
      <Suspense :timeout="timeout">
        <slot />
        <template #fallback>
          <slot name="loader">
            <Loader />
          </slot>
        </template>
      </Suspense>
    </FixedNuxtErrorBoundary>
  </div>
</template>

<script setup lang="ts" generic="E">
import { Cause, Runtime } from "effect-app"
import FixedNuxtErrorBoundary from "./FixedNuxtErrorBoundary"
import type { Refinement } from "effect/Predicate"
import { useRuntimeConfig } from "#app"

const config = useRuntimeConfig()
type BaseProps = {
  timeout?: number
}

type WithGuard = BaseProps & {
  guard?: Refinement<unknown, E>
  onError?: never
}

type WithOnError = BaseProps & {
  guard?: never
  onError: (error: unknown) => void
}

type Props = WithGuard | WithOnError

const props = withDefaults(defineProps<Props>(), {
  timeout: 500,
  guard: undefined,
  onError: undefined,
})

const handleError = (error: unknown) => {
  console.debug("handling error", error)
  if (props.onError) {
    props.onError(error)
    return
  }

  if (!props.guard || !Runtime.isFiberFailure(error)) throw error
  const cause = Cause.squash(error[Runtime.FiberFailureCauseId])
  if (!props.guard(cause)) throw error
}
</script>
