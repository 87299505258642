import { Return5YearsRanking, ReturnRiskRanking } from "#api/models/Rankings"
import { TickerSymbol } from "#api/models/Security"
import { S } from "./lib.js"
import { OperationId } from "./Views.js"

// I haven't found a better way to get it aligned w.r.t. the models' tags
const selectableRankingType = S.Literal("Return5Years", "ReturnRisk") satisfies S.Literal<
  [Return5YearsRanking["_tag"], ReturnRiskRanking["_tag"]]
>

export class GetByTickerSymbol extends S.Req<GetByTickerSymbol>()("GetByTickerSymbol", {
  symbol: TickerSymbol,
  type: selectableRankingType
}, {
  allowedRoles: ["user"],
  // buy and hold one and the imported algo one
  success: S.Union(S.Tuple(ReturnRiskRanking, ReturnRiskRanking), S.Tuple(Return5YearsRanking, Return5YearsRanking))
}) {}

export class GetByIndex extends S.Req<GetByIndex>()("GetByIndex", {
  index: S.NonEmptyString255,
  type: selectableRankingType
}, {
  allowedRoles: ["user"],
  success: S.Union(S.Array(ReturnRiskRanking), S.Array(Return5YearsRanking))
}) {}

export class GetAllByType extends S.Req<GetAllByType>()("GetAllByType", {
  type: selectableRankingType
}, {
  allowedRoles: ["user"],
  success: S.Union(S.Array(ReturnRiskRanking), S.Array(Return5YearsRanking))
}) {}

export class ComputeAll5YearsReturnRankings
  extends S.Req<ComputeAll5YearsReturnRankings>()("ComputeAll5YearsReturnRankings", {
    force: S.Boolean.pipe(S.withDefaultConstructor(() => false))
  }, {
    allowedRoles: ["manager"],
    success: OperationId
  })
{}

export class ComputeAllReturnRiskRankings
  extends S.Req<ComputeAllReturnRiskRankings>()("ComputeAllReturnRiskRankings", {
    force: S.Boolean.pipe(S.withDefaultConstructor(() => false))
  }, {
    allowedRoles: ["manager"],
    success: OperationId
  })
{}

export class RemoveAll extends S.Req<RemoveAll>()("RemoveAll", {
  type: selectableRankingType.pipe(S.optional)
}, {
  allowedRoles: ["manager"]
}) {}

// codegen:start {preset: model}
//
/* eslint-disable */
/* eslint-enable */
//
// codegen:end

// codegen:start {preset: meta, sourcePrefix: "src/resources/"}
export const meta = { moduleName: "Rankings" } as const
// codegen:end
