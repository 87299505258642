/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeClient, type Opts, type ResponseErrors } from "@effect-app/vue"
import { useToast } from "vue-toastification"
import { useIntl } from "./intl"
import { runtime } from "./runtime"
import type {} from "effect-app/Schema/brand"
import { clientFor as clientFor_ } from "#resources/lib"
import {
  type Requests,
  type RequestHandler,
  type RequestHandlerWithInput,
  type TaggedRequestClassAny,
} from "effect-app/client/clientFor"
import { OperationsClient } from "#resources/Operations"
import * as Result from "@effect-rx/rx/Result"
import * as Sentry from "@sentry/browser"
import {
  type RouteLocationRaw,
  isNavigationFailure as isNavigationFailureOriginal,
  type NavigationFailure,
} from "vue-router"
import { onUnmounted, ref, watch, shallowRef, type Ref } from "vue"

import { Cause, Data, Effect, type Exit } from "effect-app"

import { navigateTo } from "#app"
import { tuple } from "effect-app/Function"

export { useToast } from "vue-toastification"

export { Result, makeContext } from "@effect-app/vue"
export {
  pauseWhileProcessing,
  useIntervalPauseWhileProcessing,
  composeQueries,
  SuppressErrors,
  mapHandler,
} from "@effect-app/vue"

export const run = runtime.runPromise
export const runSync = runtime.runSync

export const clientFor = <M extends Requests>(m: M) => runSync(clientFor_(m))
export const useOperationsClient = () => runSync(OperationsClient)

export const {
  buildFormFromSchema,
  makeUseAndHandleMutation,
  useAndHandleMutation,
  useAndHandleMutationResult: useAndHandleMutationResult_,
  useSafeMutation,
  useSafeMutationWithState,
  useSafeQuery,
  useSafeSuspenseQuery,
} = makeClient(useIntl, useToast, shallowRef(runtime.runtime)) // TODO

// copied types from nuxt because they're not otherwise accessible it seems :/
type Without<T, U> = {
  [P in Exclude<keyof T, keyof U>]?: never
}
type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U
export type OpenWindowFeatures = {
  popup?: boolean
  noopener?: boolean
  noreferrer?: boolean
} & XOR<
  {
    width?: number
  },
  {
    innerWidth?: number
  }
> &
  XOR<
    {
      height?: number
    },
    {
      innerHeight?: number
    }
  > &
  XOR<
    {
      left?: number
    },
    {
      screenX?: number
    }
  > &
  XOR<
    {
      top?: number
    },
    {
      screenY?: number
    }
  >
export type OpenOptions = {
  target: "_blank" | "_parent" | "_self" | "_top" | (string & {})
  windowFeatures?: OpenWindowFeatures
}
export interface NavigateToOptions {
  replace?: boolean
  redirectCode?: number
  external?: boolean
  open?: OpenOptions
}

// the original does not properly narrow to the wider "NavigationFailure" when omitting "type" arg.
const isNavigationFailure: (error: any) => error is NavigationFailure =
  isNavigationFailureOriginal

export class NavigationError extends Data.TaggedError("NavigationError")<{
  reason: NavigationFailure
}> {}

/**
 * When navigating away from the page as a result of a mutation, we still want the loading state of the mutation to remain locked
 * while the next page is being loaded, to prevent double submit.
 */
export const useNavigateToAndWait = () => {
  const unmounted_ = ref(false)
  onUnmounted(() => (unmounted_.value = true))

  const unmounted = Effect.async<void>((cb, signal) => {
    const handle = watch(unmounted_, value =>
      value ? cb(Effect.succeed(void 0)) : null,
    )
    signal.addEventListener("abort", () => handle.stop())
  })

  return (
    loc: RouteLocationRaw | undefined | null,
    options?: NavigateToOptions,
  ) =>
    Effect.gen(function* () {
      const result = yield* Effect.promise(() =>
        Promise.resolve(navigateTo(loc, options)),
      )

      if (result === false) {
        return result
      }
      if (isNavigationFailure(result)) {
        return yield* new NavigationError({ reason: result })
      }
      yield* unmounted
      return result
    })
}

/**
 * When the navigation fails, it will show a toast but the effect will still succeed.
 * Wraps navigateToAndWait in a way that it will retry once if the navigation fails.
 * Returns the Exit so it can still be inspected for decision making.
 */
export const useHandledNavigation = () => {
  const navigateToAndWait = useNavigateToAndWait()
  const toast = useToast()
  const { trans } = useIntl()

  return (
    loc: RouteLocationRaw | undefined | null,
    options?: NavigateToOptions,
  ) =>
    navigateToAndWait(loc, options).pipe(
      Effect.tapErrorTag("NavigationError", error =>
        Effect.sync(() => {
          Sentry.captureMessage("NavigationError", {
            extra: { action: loc?.toString() ?? "unknown", error },
          })
          toast.error(
            trans("navigation.failed", { loc: loc?.toString() ?? "unknown" }),
            {
              timeout: false,
              onClick: () => {
                // let's try again once
                run(navigateToAndWait(loc, options))
              },
            },
          )
        }),
      ),
      Effect.exit,
    )
}

// TODO: extract to @effect-app/vue
export const getError = <A, E>(self: Result.Result<A, E>) => {
  if (!Result.isFailure(self)) {
    return undefined
  }
  const e = Cause.failureOrCause(self.cause)
  if (e._tag === "Right") {
    return undefined
  }
  return e.left
}

/**
 * A variation of {@link useAndHandleMutationResult_} that accepts an optional onSuccessNavigateTo option,
 * to navigate to after the mutation has succeeded.
 */
export const useAndHandleMutationResult: {
  <
    I,
    E extends ResponseErrors,
    A,
    R,
    Request extends TaggedRequestClassAny,
    A2 = A,
    E2 extends ResponseErrors = E,
    R2 = R,
    ESuccess = never,
    RSuccess = never,
    EError = never,
    RError = never,
    EDefect = never,
    RDefect = never,
  >(
    self: RequestHandlerWithInput<I, A, E, R, Request>,
    action: string,
    options?: Opts<
      A,
      E,
      R,
      I,
      A2,
      E2,
      R2,
      ESuccess,
      RSuccess,
      EError,
      RError,
      EDefect,
      RDefect
    > & {
      onSuccessNavigateTo?:
        | RouteLocationRaw
        | undefined
        | null
        | ((a: A, i: I) => RouteLocationRaw | undefined | null)
    },
  ): [
    Readonly<Ref<Result.Result<A2, E2>, Result.Result<A2, E2>>>,
    (i: I) => Effect<Exit<A2, E2>, never, R2>,
  ]
  <
    E extends ResponseErrors,
    A,
    R,
    Request extends TaggedRequestClassAny,
    A2 = A,
    E2 extends ResponseErrors = E,
    R2 = R,
    ESuccess = never,
    RSuccess = never,
    EError = never,
    RError = never,
    EDefect = never,
    RDefect = never,
  >(
    self: RequestHandler<A, E, R, Request>,
    action: string,
    options?: Opts<
      A,
      E,
      R,
      void,
      A2,
      E2,
      R2,
      ESuccess,
      RSuccess,
      EError,
      RError,
      EDefect,
      RDefect
    > & {
      onSuccessNavigateTo?:
        | RouteLocationRaw
        | undefined
        | null
        | ((a: A) => RouteLocationRaw | undefined | null)
    },
  ): [
    Readonly<Ref<Result.Result<A2, E2>, Result.Result<A2, E2>>>,
    Effect<Exit<A2, E2>, never, R2>,
  ]
} = (
  self: any,
  action: any,
  options?: Opts<
    any,
    any,
    any,
    any,
    any,
    any,
    any,
    any,
    any,
    any,
    any,
    any,
    any
  > & {
    onSuccessNavigateTo?:
      | RouteLocationRaw
      | undefined
      | null
      | ((a: any, i: any) => RouteLocationRaw | undefined | null)
  },
): any => {
  const navigateToAndWait = useHandledNavigation()
  const onSuccessNavigateTo = options?.onSuccessNavigateTo

  const [state, mutation] = useAndHandleMutationResult_(self, action, {
    ...options,
    mapHandler: (handler, input) =>
      handler.pipe(
        Effect.tap(a =>
          onSuccessNavigateTo
            ? // we are part of the mutation lifecycle but don't care about the result
              navigateToAndWait(
                typeof onSuccessNavigateTo === "function"
                  ? onSuccessNavigateTo(a, input)
                  : onSuccessNavigateTo,
              )
            : Effect.void,
        ),
      ),
  })

  return tuple(state, mutation)
}
