<template>
  <client-only>
    <Overall>
      <v-app>
        <NuxtLayout>
          <NuxtPwaManifest />
          <NuxtPage />
        </NuxtLayout>
      </v-app>
    </Overall>
  </client-only>
</template>

<script setup lang="ts">
import { Effect } from "effect"
import { mapHandler, useSafeQuery } from "./composables/client"
import "./layouts/shared"
import { setup } from "~/composables/setup"
import Overall from "./layouts/overall.vue"
import { accountsClient } from "./composables/clients"
import { useIntl } from "./composables/intl"
import { provideIntl } from "@effect-app/vue-components"
import "@effect-app/vue-components/dist/vue-components.css"

const intl = useIntl()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
provideIntl(intl as any)

// TODO: a Mutation is not a Query...
useSafeQuery(
  mapHandler(
    accountsClient.PingMe,
    Effect.map(() => "bogus"),
  ),
  {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: true,
  },
)

setup()
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.page-enter-active,
.page-leave-active {
  transition: all 0.1s;

  @media (prefers-reduced-motion: prefer-no-motion) {
    transition: all 0s;
  }
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.5rem);
}
</style>
