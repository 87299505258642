
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexWmLxFE1ZcJW6MnU_MoSfVGmaF5awTo1m3gbiyiQ7FjoMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/index.vue?macro=true";
import { default as uploadfbAlxoYQHlCEeIHobLY8ybxwuIxkUwRGTZt2DxyUvosMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/upload.vue?macro=true";
import { default as _UploadBgqCKel7_45OiJwLFPK4VftIoZFIgV_45UczD6EoP3yb_8IMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/_Upload.vue?macro=true";
import { default as ranking_1qqBIm7IszwRqGdn1ZTXt_45Qgz_45n5rbchKm_45MtC_45KtUMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/ranking.vue?macro=true";
import { default as index4GMaInXLa72ozXgX72OhqpSVOjUHsaxLkMdiYuFtg9QMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/index.vue?macro=true";
import { default as _91id_939RjbEcuywC6ZMCX6D4rK80CYrw8l4MXYXXpEUKuzrikMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/[id].vue?macro=true";
import { default as _91news_93UQcaBrFK3MZ_454Hy4BkcyepK16fksYQjCgaOVo_jVxAQMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/[news].vue?macro=true";
import { default as create_455LTOQvNs1uPcEDaKRn9JiwUX1qlfZ_W3grv6cT7cUwMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/create.vue?macro=true";
import { default as searchpSN0qhU_45n6O2VJIrksxVloBOIdUIbF1uXQNSiXC_45jXAMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/search.vue?macro=true";
import { default as indexBAQwBzICQwnZ_45gy5oxDahpzc1wrymSlmWPooswsMqk0Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/index.vue?macro=true";
import { default as indextvHRpG63gMrM8jCBo__L7yzq2SZ1oaxLlOm15wsRGQkMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/playground/index.vue?macro=true";
import { default as indexEQEpNL6AiCqC1x8t_450GvmeABT9P24ebW8Ev4n_CVQ54Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/index.vue?macro=true";
import { default as indexdKMLA_ZKxPgRZcI_45l5FGbm30M80ge297InLWuSZuLnkMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/optimizations/index.vue?macro=true";
import { default as searchvdiurwbMhs8wjsS1UHN1eSZiVEDTCcm4KnCrZ0a1nZYMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/search.vue?macro=true";
import { default as searchBarp0lSMztWiev1MHdoIN4pbBQTF_453_45PW14QsEsGer7dUYMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/searchBar.vue?macro=true";
import { default as _91ticker_93_AdVIZIN84Q9XdSejIOxuTa_VLSHjh1xJcBKx_7qm4cMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/tickers/[ticker].vue?macro=true";
import { default as _91id_93ff8bcT2LpvHXLoFXvz9ANR12jFrPoYpQeo3_454hMGiLoMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/[id].vue?macro=true";
import { default as createX2PXEnUdE9QIfm9iIL2zYy82Zt0Nsv5MTlsPvCbl1XAMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/create.vue?macro=true";
import { default as adminUUjm6AAvPydVZwL1VcnddT3VhzdAaRD0qLcFmx3adJ4Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/admin.vue?macro=true";
import { default as indexqJmxtYehONPbcaN_458E3Ddm8iYUFUlKRa5HH3YKA_45RL4Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/index.vue?macro=true";
import { default as logoutR2h89nEzdRIRCU8tkoOKMFXzyskoHUv4E2ByM_BPvy4Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/logout.vue?macro=true";
import { default as searchClPY409fE_45b0ufyfexVmM30SZLspdCsNT0CxCdnvHZcMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/search.vue?macro=true";
import { default as refreshKLlMjX42RTUlyexOOG_45ZjyRNlgpApMgB1S3FTaf5BVsMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/refresh.vue?macro=true";
import { default as activateaKmrw3eZqS_45QgatpXJR6FhSTqJMHcHdO_6mm9BB0QMoMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/activate.vue?macro=true";
import { default as settingsFToYmo37IWy_KS35nqqTiqeQPahgcglsJFgAGj8Db5oMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/settings.vue?macro=true";
import { default as _91_46_46_46slug_93CFBn3vdaSl2xRrC9XQvBuQ2DISIjWWqDj_452jZWRf33AMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/[...slug].vue?macro=true";
import { default as indexhHDcdQuq3wmLqMhoyaSeYk7puWxmnkOZjtcpcVeycngMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/news/index.vue?macro=true";
import { default as _91news_93F1OpDOomVj2bnVsj73tfUVHjmoqTNYuqy93QAlUY8VAMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/news/[news].vue?macro=true";
import { default as indexmjZWzTwxjktZgEvvFv1rgdDEH3fnJBo0wIobxuhhNQQMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/terms/index.vue?macro=true";
import { default as _Terms4H9262MTJrkK13B2xfmNSZf5Jn5ZwV9P2X9J2P1lFpIMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/terms/_Terms.vue?macro=true";
import { default as acceptl8SyjBtVIe0OCQWZTQ62eC8Lc93GEKe0yDV9aDQX03oMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/terms/accept.vue?macro=true";
import { default as index5uiV7loPnyCRaNrm1OJTi_3ropLFtq46mU4Akc4NeJAMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/privacy/index.vue?macro=true";
import { default as indexkvMYHx3Z_45jR5O_qi4rmnzR_45bEN4Vh3XvavThxqJeBzYMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/support/index.vue?macro=true";
import { default as indexaUFlfqdcPCMwliU9VBRnjQOhGOYLF5mcSUMoxdYdSY4Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/portfolio/index.vue?macro=true";
import { default as indexNOWkeBp_1XDd5aMHr_ZP_4568ZPcwPal5F_45SDTD4640EsMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/index.vue?macro=true";
import { default as _91news_93KyrJ9qCSZBpnO4mdPgaZiQXZtQ9_jJ_GxdVlx_45OW_fQMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/news/show/[news].vue?macro=true";
import { default as _PrivacyeojFyy4mV_CpD_45F6w228Wu2IpsC6y3m0CAZidSdXq98Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/privacy/_Privacy.vue?macro=true";
import { default as index0eUQjmOYSt7K7pq1_453OgONw59jzP1h6n2wgBK9EF_45N4Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/index.vue?macro=true";
import { default as _91plan_93Q45Qcf3IqCUE6YYi1T_45yuA_k_453UJYhwdPQI3ARXB5W8Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/[plan].vue?macro=true";
import { default as _91ticket_93ya4Rt_HEuCPLgtaWjx9iRBAzN28Fjv_457dHX_45H2g4a6wMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/support/[ticket].vue?macro=true";
import { default as updatedeU58uLLxzG6x3CPq6Fpr8GWo8v6NvrtlZZgVSdMv980Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/updated.vue?macro=true";
import { default as _Ticker9Fei6_45fzTCjnQfirZdiTbjcI6nNRUvsiQxk3kg_45uMfEMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Ticker.vue?macro=true";
import { default as _91ticker_93xEU6coZrGPP8ytsvjearIS7x2_45RZmNXhqz2dwdbpYTMMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/[ticker].vue?macro=true";
import { default as _Commentsj_45fgPuG64khpiF6Rr3bSj0Ev1DWdWellLtBO5qrnRkcMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Comments.vue?macro=true";
import { default as _EtfTable1w1SebzDhDJFxdRWMW39HI35kT8VegGWktmgEuVdW8gMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_EtfTable.vue?macro=true";
import { default as processingxFca7dKyz_2OVGIY6KRcRo9CVeOppvQgNbnF2cFo9_cMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/processing.vue?macro=true";
import { default as create_45ticketd5dLxeQLFI9ghxza4hgJiCqp1JLK0aXEeH_0n4nlvqYMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/support/create-ticket.vue?macro=true";
import { default as unsubscribed6DBg3otEAdI_iuee1c_45bIl_4551ZjPeuRGUw4O50GYIXQMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/unsubscribed.vue?macro=true";
import { default as _PremiumDialogXxd_456Qx8u5T6T_Hh731fLNL_455tVkkPpshhzhivOLcLgMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_PremiumDialog.vue?macro=true";
import { default as _SelectStockIndexI27HgJbk4rpAoA9xhle23R4Og34TKq8p0tXnI8yvBHsMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SelectStockIndex.vue?macro=true";
import { default as already_45subscribed77zKM0dTfJvWVfNVuXvcf6UjDmRU95ToobnyDJ2OhVYMeta } from "/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/already-subscribed.vue?macro=true";
import { default as _SubscriptionToggle9uW5_45_ucfsAs0ciUidl0xJ9c_NbHEbhPcZxotEegLc8Meta } from "/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SubscriptionToggle.vue?macro=true";
export default [
  {
    name: adminUUjm6AAvPydVZwL1VcnddT3VhzdAaRD0qLcFmx3adJ4Meta?.name,
    path: "/admin",
    meta: adminUUjm6AAvPydVZwL1VcnddT3VhzdAaRD0qLcFmx3adJ4Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin.vue"),
    children: [
  {
    name: "admin",
    path: "",
    meta: indexWmLxFE1ZcJW6MnU_MoSfVGmaF5awTo1m3gbiyiQ7FjoMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/index.vue")
  },
  {
    name: "admin-upload",
    path: "upload",
    meta: uploadfbAlxoYQHlCEeIHobLY8ybxwuIxkUwRGTZt2DxyUvosMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/upload.vue")
  },
  {
    name: "admin-_Upload",
    path: "_Upload",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/_Upload.vue")
  },
  {
    name: "admin-ranking",
    path: "ranking",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/ranking.vue")
  },
  {
    name: "admin-news",
    path: "news",
    meta: index4GMaInXLa72ozXgX72OhqpSVOjUHsaxLkMdiYuFtg9QMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/[id].vue")
  },
  {
    name: "admin-news-news",
    path: "news/:news()",
    meta: _91news_93UQcaBrFK3MZ_454Hy4BkcyepK16fksYQjCgaOVo_jVxAQMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/[news].vue")
  },
  {
    name: "admin-news-create",
    path: "news/create",
    meta: create_455LTOQvNs1uPcEDaKRn9JiwUX1qlfZ_W3grv6cT7cUwMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/create.vue")
  },
  {
    name: "admin-news-search",
    path: "news/search",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/search.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/users/index.vue")
  },
  {
    name: "admin-playground",
    path: "playground",
    meta: indextvHRpG63gMrM8jCBo__L7yzq2SZ1oaxLlOm15wsRGQkMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/playground/index.vue")
  },
  {
    name: "admin-securities",
    path: "securities",
    meta: indexEQEpNL6AiCqC1x8t_450GvmeABT9P24ebW8Ev4n_CVQ54Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/index.vue")
  },
  {
    name: "admin-optimizations",
    path: "optimizations",
    meta: indexdKMLA_ZKxPgRZcI_45l5FGbm30M80ge297InLWuSZuLnkMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/optimizations/index.vue")
  },
  {
    name: "admin-news-_components-search",
    path: "news/_components/search",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/search.vue")
  },
  {
    name: "admin-news-_components-searchBar",
    path: "news/_components/searchBar",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/news/_components/searchBar.vue")
  },
  {
    name: "admin-securities-tickers-ticker",
    path: "securities/tickers/:ticker()",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/tickers/[ticker].vue")
  },
  {
    name: "admin-securities-ticker-group-id",
    path: "securities/ticker-group/:id()",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/[id].vue")
  },
  {
    name: "admin-securities-ticker-group-create",
    path: "securities/ticker-group/create",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/admin/securities/ticker-group/create.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexqJmxtYehONPbcaN_458E3Ddm8iYUFUlKRa5HH3YKA_45RL4Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutR2h89nEzdRIRCU8tkoOKMFXzyskoHUv4E2ByM_BPvy4Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/logout.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchClPY409fE_45b0ufyfexVmM30SZLspdCsNT0CxCdnvHZcMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/search.vue")
  },
  {
    name: "refresh",
    path: "/refresh",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/refresh.vue")
  },
  {
    name: "activate",
    path: "/activate",
    meta: activateaKmrw3eZqS_45QgatpXJR6FhSTqJMHcHdO_6mm9BB0QMoMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/activate.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsFToYmo37IWy_KS35nqqTiqeQPahgcglsJFgAGj8Db5oMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/settings.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93CFBn3vdaSl2xRrC9XQvBuQ2DISIjWWqDj_452jZWRf33AMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/[...slug].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexhHDcdQuq3wmLqMhoyaSeYk7puWxmnkOZjtcpcVeycngMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/news/index.vue")
  },
  {
    name: "news-news",
    path: "/news/:news()",
    meta: _91news_93F1OpDOomVj2bnVsj73tfUVHjmoqTNYuqy93QAlUY8VAMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/news/[news].vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: indexmjZWzTwxjktZgEvvFv1rgdDEH3fnJBo0wIobxuhhNQQMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/terms/index.vue")
  },
  {
    name: "terms-_Terms",
    path: "/terms/_Terms",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/terms/_Terms.vue")
  },
  {
    name: "terms-accept",
    path: "/terms/accept",
    meta: acceptl8SyjBtVIe0OCQWZTQ62eC8Lc93GEKe0yDV9aDQX03oMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/terms/accept.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: index5uiV7loPnyCRaNrm1OJTi_3ropLFtq46mU4Akc4NeJAMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/privacy/index.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexkvMYHx3Z_45jR5O_qi4rmnzR_45bEN4Vh3XvavThxqJeBzYMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/support/index.vue")
  },
  {
    name: "portfolio",
    path: "/portfolio",
    meta: indexaUFlfqdcPCMwliU9VBRnjQOhGOYLF5mcSUMoxdYdSY4Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/portfolio/index.vue")
  },
  {
    name: "subscribe",
    path: "/subscribe",
    meta: indexNOWkeBp_1XDd5aMHr_ZP_4568ZPcwPal5F_45SDTD4640EsMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/index.vue")
  },
  {
    name: "news-show-news",
    path: "/news/show/:news()",
    meta: _91news_93KyrJ9qCSZBpnO4mdPgaZiQXZtQ9_jJ_GxdVlx_45OW_fQMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/news/show/[news].vue")
  },
  {
    name: "privacy-_Privacy",
    path: "/privacy/_Privacy",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/privacy/_Privacy.vue")
  },
  {
    name: "securities",
    path: "/securities",
    meta: index0eUQjmOYSt7K7pq1_453OgONw59jzP1h6n2wgBK9EF_45N4Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/index.vue")
  },
  {
    name: "subscribe-plan",
    path: "/subscribe/:plan()",
    meta: _91plan_93Q45Qcf3IqCUE6YYi1T_45yuA_k_453UJYhwdPQI3ARXB5W8Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/[plan].vue")
  },
  {
    name: "support-ticket",
    path: "/support/:ticket()",
    meta: _91ticket_93ya4Rt_HEuCPLgtaWjx9iRBAzN28Fjv_457dHX_45H2g4a6wMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/support/[ticket].vue")
  },
  {
    name: "subscribe-updated",
    path: "/subscribe/updated",
    meta: updatedeU58uLLxzG6x3CPq6Fpr8GWo8v6NvrtlZZgVSdMv980Meta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/updated.vue")
  },
  {
    name: "securities-_Ticker",
    path: "/securities/_Ticker",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Ticker.vue")
  },
  {
    name: "securities-ticker",
    path: "/securities/:ticker()",
    meta: _91ticker_93xEU6coZrGPP8ytsvjearIS7x2_45RZmNXhqz2dwdbpYTMMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/[ticker].vue")
  },
  {
    name: "securities-_Comments",
    path: "/securities/_Comments",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_Comments.vue")
  },
  {
    name: "securities-_EtfTable",
    path: "/securities/_EtfTable",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_EtfTable.vue")
  },
  {
    name: "subscribe-processing",
    path: "/subscribe/processing",
    meta: processingxFca7dKyz_2OVGIY6KRcRo9CVeOppvQgNbnF2cFo9_cMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/processing.vue")
  },
  {
    name: "support-create-ticket",
    path: "/support/create-ticket",
    meta: create_45ticketd5dLxeQLFI9ghxza4hgJiCqp1JLK0aXEeH_0n4nlvqYMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/support/create-ticket.vue")
  },
  {
    name: "subscribe-unsubscribed",
    path: "/subscribe/unsubscribed",
    meta: unsubscribed6DBg3otEAdI_iuee1c_45bIl_4551ZjPeuRGUw4O50GYIXQMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/unsubscribed.vue")
  },
  {
    name: "securities-_PremiumDialog",
    path: "/securities/_PremiumDialog",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_PremiumDialog.vue")
  },
  {
    name: "securities-_SelectStockIndex",
    path: "/securities/_SelectStockIndex",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SelectStockIndex.vue")
  },
  {
    name: "subscribe-already-subscribed",
    path: "/subscribe/already-subscribed",
    meta: already_45subscribed77zKM0dTfJvWVfNVuXvcf6UjDmRU95ToobnyDJ2OhVYMeta || {},
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/subscribe/already-subscribed.vue")
  },
  {
    name: "securities-_SubscriptionToggle",
    path: "/securities/_SubscriptionToggle",
    component: () => import("/home/runner/work/signalznow/signalznow/frontend/pages/securities/_SubscriptionToggle.vue")
  }
]