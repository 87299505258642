import revive_payload_client_uU_uJ2YF_oA5C1fAQQ8lsrxjXMMM3MmN292eIEabAik from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yfeLbfUfp1wQXq_Y7xCkQq_8PAWJoSxSzhiz0D9tXnA from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IK4uknp0o2uWISMwEho5PMP12_gwAaV6vQ_e1nXGdV4 from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_HHgRrI9oSCG3Z2nQQBcpSS9gjTbuFfY_gjTRGuRQdNc from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jy5HppuJsJEgicOiDFIbykzfGB7ABqEU_Gqspa7cxC8 from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_35bAQEnEp4QEHTWlzsn0gzMSrkS_4Nux_NWCbQM_jiQ from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2ikro4sRkCNtK_wcjuupH9fZxsgNN8L6CIzGRUT1PYk from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/runner/work/signalznow/signalznow/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_dd2dg_8aNCXKGf8V3K2Wng5JQOKyOjWoTGyzDua9MKY from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_@parcel+watcher@2.5.1_@type_e8f02b8fcdfe0b9a584733dc00861fff/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/home/runner/work/signalznow/signalznow/frontend/.nuxt/pwa-icons-plugin.ts";
import plugin_VfYjtFyuInYwQJAIRIXbxcfAQJFJJpLbLjma5H2c_f8 from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_patch_hash=3a47c9acff9a26d4adf642cf2f9e201793131837bc_c79ec4ed27092c3b35910ff817dc5283/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_4HPsBUCkY6seYUzzcNAeYumaJQFJwLE3qY0ClAhbSMQ from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.1_@azure+cosmos@4.3.0_@azure+storage-blob@12.27.0_magicast@0.3_54922b49a076675f6d79e3d5b8655d08/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import plugin_m7zOFmrv54smyyyJtTU9IIhbWM3yNBwnwdvs_09fT4M from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.16.2_@azure+cosmos@4.3.0_@azure+_2b6fa042eba0b190ea12ae76f06cb495/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/home/runner/work/signalznow/signalznow/frontend/plugins/error-handler.ts";
import observability_u3J4Ykz2_1uwGpvM4azsWe1FdDCIsWTkQXTqgph_fHU from "/home/runner/work/signalznow/signalznow/frontend/plugins/observability.ts";
import timeago_Crmq_D5qXo3bmlWfW3qCEhSEQQhw9uZVy_Pd3JIv5gw from "/home/runner/work/signalznow/signalznow/frontend/plugins/timeago.ts";
import toastification_MXZl6_lg_Ouk9PMhbMPRqFLGbelTrikQyV6_wtPTUtY from "/home/runner/work/signalznow/signalznow/frontend/plugins/toastification.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/home/runner/work/signalznow/signalznow/frontend/plugins/vuetify.ts";
import pwa_client_ls_dcUsACxdIT8K_N25tonWGFPCHFKLAXdnMvB_2ySA from "/home/runner/work/signalznow/signalznow/node_modules/.pnpm/@vite-pwa+nuxt@1.0.0_magicast@0.3.5_vite@6.2.4_@types+node@22.13.16_jiti@2.4.2_sass@1.8_ad9b286062152bbebc5a91714c12214c/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  revive_payload_client_uU_uJ2YF_oA5C1fAQQ8lsrxjXMMM3MmN292eIEabAik,
  unhead_yfeLbfUfp1wQXq_Y7xCkQq_8PAWJoSxSzhiz0D9tXnA,
  router_IK4uknp0o2uWISMwEho5PMP12_gwAaV6vQ_e1nXGdV4,
  payload_client_HHgRrI9oSCG3Z2nQQBcpSS9gjTbuFfY_gjTRGuRQdNc,
  navigation_repaint_client_jy5HppuJsJEgicOiDFIbykzfGB7ABqEU_Gqspa7cxC8,
  check_outdated_build_client_35bAQEnEp4QEHTWlzsn0gzMSrkS_4Nux_NWCbQM_jiQ,
  chunk_reload_client_2ikro4sRkCNtK_wcjuupH9fZxsgNN8L6CIzGRUT1PYk,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_dd2dg_8aNCXKGf8V3K2Wng5JQOKyOjWoTGyzDua9MKY,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  plugin_VfYjtFyuInYwQJAIRIXbxcfAQJFJJpLbLjma5H2c_f8,
  plugin_4HPsBUCkY6seYUzzcNAeYumaJQFJwLE3qY0ClAhbSMQ,
  plugin_m7zOFmrv54smyyyJtTU9IIhbWM3yNBwnwdvs_09fT4M,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  observability_u3J4Ykz2_1uwGpvM4azsWe1FdDCIsWTkQXTqgph_fHU,
  timeago_Crmq_D5qXo3bmlWfW3qCEhSEQQhw9uZVy_Pd3JIv5gw,
  toastification_MXZl6_lg_Ouk9PMhbMPRqFLGbelTrikQyV6_wtPTUtY,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds,
  pwa_client_ls_dcUsACxdIT8K_N25tonWGFPCHFKLAXdnMvB_2ySA
]